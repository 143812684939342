/*eslint-disable */
import { left, right } from 'fp-ts/Either';
import { BaseApi } from '../../../../hexagon/infra/BaseApi';
import {
    RecordIds,
    UpdateStatus,
    TVehicle,
    TVehicleState,
    TCustomer,
    TRecord,
    TAppointment,
    TJourney,
    TEmailCategory,
    TReason,
    BuyingProjectMake,
} from '../../../../hexagon/interfaces';
import { ApiResponse } from '../../../../hexagon/infra/ApiResponse';
import { RecordGateway } from '../../../../hexagon/gateways/recordGateway.interface';

export class InMemoryRecordGateway extends BaseApi implements RecordGateway {
    private recordIds = {} as RecordIds;

    private records = [] as TRecord[];

    private cancellationReasons = [] as TReason[];

    private makes = [] as BuyingProjectMake[];

    async saveVehicleInformation(
        identifier: string,
        offer: TJourney,
        vehicleInformation: TVehicle,
        origin?: string,
    ): Promise<ApiResponse<RecordIds>> {
        if (this.recordIds) {
            return right(this.recordIds);
        }

        return left('unknown record');
    }

    async saveVehicleStateInformation(
        identifier: string,
        recordUid: string,
        vehicleState: TVehicleState,
    ): Promise<ApiResponse<RecordIds>> {
        if (this.recordIds) {
            return right(this.recordIds);
        }

        return left('unknown record');
    }

    async saveUserInformation(
        identifier: string,
        recordUid: string,
        vehicleUserInformation: TCustomer,
    ): Promise<ApiResponse<RecordIds>> {
        if (this.recordIds) {
            return right(this.recordIds);
        }

        return left('unknown record');
    }

    async updateUserInformation(
        identifier: string,
        recordUid: string,
        vehicleUserInformation: TCustomer,
    ): Promise<ApiResponse<UpdateStatus>> {
        if (this.recordIds) {
            return right({ status: true });
        }

        return left('unknown record');
    }

    async getRecord(
        identifier: string,
        recordUid: string,
        mode?: string,
    ): Promise<ApiResponse<TRecord>> {
        const found = this.records.find((e) => e.uid === recordUid);

        if (found) {
            return right(found);
        }

        return left('unknown record');
    }

    async cancelAppointment(identifier: string, recordUid: string): Promise<ApiResponse<boolean>> {
        const found = this.records.find((e) => e.uid === recordUid);
        if (!found) {
            return left('error_cancel_appointement');
        }
        delete found.appointment;
        found.offerStatus = 'NO_APPOINTMENT';

        return right(true);
    }

    async createTrakingReason(
        identifier: string,
        recordUid: string,
        reasonId?: number,
        otherComment?: string,
    ): Promise<ApiResponse<UpdateStatus>> {
        return right({ status: true });
    }

    async createQuotation(identifier: string, recordUid: string): Promise<ApiResponse<boolean>> {
        return right(true);
    }

    async duplicateRecord(identifier: string, recordUid: string): Promise<ApiResponse<RecordIds>> {
        const result: RecordIds = { id: 100, uid: 'aaa' };
        return right(result);
    }

    async updatePurchaseProject(
        identifier: string,
        recordUid: string,
        delay: string,
        make?: string,
    ): Promise<ApiResponse<UpdateStatus>> {
        return right({ status: true });
    }

    feedRecordIdsWith(recordIds: RecordIds) {
        this.recordIds = recordIds;
    }

    feedRecordsWith(records: TRecord[]) {
        this.records = records;
    }

    async createAppointment(
        identifier: string,
        recordUid: string,
        resaId: number,
        hbsInd: boolean,
    ): Promise<ApiResponse<TAppointment>> {
        const found = this.records.find((e) => e.uid === recordUid);

        if (!found) {
            return left('error_create_appointement');
        }
        found.offerStatus = 'CONFIRMED';
        found.appointment = <TAppointment>{
            id: 39132,
            createdAt: '2021-09-16 12:18:51',
            updatedAt: '0000-00-00 00:00:00',
            status: Boolean(1),
            lastOne: 'yes',
            active: 'yes',
            appointmentDate: 'Vendredi, 22 de avril',
            startHour: '15:00',
            endHour: '1600',
            expertId: 1636907,
            expertName: 'Expert PDV',
            expertPicture:
                'http://static.shakazoola.com/b2c_fr/web/public/images/imgNonDispoExpert.png',
            networkId: 5701,
            dealerId: 503546,
            dealerName: 'TEST PDV 2',
            realDealerName: 'real TEST PDV 2',
            address: 'Paris, test, 70',
            zipCode: 25633,
            city: 'Paris',
            phone: '123466',
            latitude: 1.2,
            longitude: 1.6,
            addressPrecision: '',
            smsConfirmation: false,
        };
        return right(found.appointment);
    }
    async reportAppointment(recordUid: string): Promise<ApiResponse<boolean>> {
        const found = this.records.find((e) => e.uid === recordUid);
        if (!found) {
            return left('error_report_appointement');
        }
        return right(true);
    }

    async createIndicator(
        identifier: string,
        recordUid: string,
        name: string,
        value: number,
    ): Promise<ApiResponse<UpdateStatus>> {
        return right({ status: true });
    }

    async updateNotRollingProject(
        identifier: string,
        recordUid: string,
    ): Promise<ApiResponse<UpdateStatus>> {
        return right({ status: true });
    }

    async sendMail(
        identifier: string,
        recordUid: string,
        emailCategory: TEmailCategory,
    ): Promise<ApiResponse<UpdateStatus>> {
        return right({ status: true });
    }

    async sendSms(
        identifier: string,
        recordUid: string,
        phone: string,
    ): Promise<ApiResponse<UpdateStatus>> {
        return right({ status: true });
    }

    async requesCancellationReasons(identifier: string): Promise<ApiResponse<TReason[]>> {
        try {
            const result = this.cancellationReasons;
            return right(result);
        } catch (error) {
            return left(error as string);
        }
    }

    feedCancellationReasonsWith(reasons: TReason[]) {
        this.cancellationReasons = reasons;
    }

    async requestBuyingProjectMakes(
        identifier: string,
        type: string,
    ): Promise<ApiResponse<BuyingProjectMake[]>> {
        try {
            const result = this.makes;
            return right(result);
        } catch (error) {
            return left(error as string);
        }
    }

    feedBuyingProjectMakeListWith(makes: BuyingProjectMake[]) {
        this.makes = makes;
    }

    async confirmAppointment(identifier: string, recordUid: string): Promise<ApiResponse<boolean>> {
        const found = this.records.find((e) => e.uid === recordUid);
        if (!found) {
            return left('error_confirm_appointement');
        }

        return right(true);
    }

    async saveRecordPSAInformation(
        identifier: string,
        psaRecordUid: string,
        offer: TJourney,
        origin?: string,
    ): Promise<ApiResponse<RecordIds>> {
        if (this.recordIds) {
            return right(this.recordIds);
        }

        return left('unknown psa record');
    }
}

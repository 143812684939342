import { faCalendarAlt, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from 'reactstrap';
import { Hour } from '../../../../hexagon/interfaces';
import { getDealerSlotListSelector } from '../../view-models-generators/dealerSelectors';
import useTranslation from '../hooks/useTranslation';
import { InputValidation } from './InputValidation';
import { InputWithValidation } from './InputWithValidation';

type TAppointmentDateInputProps = {
    date: string;
    setDate: any;
    hour: string;
    setHour: any;
    hourList: Hour[];
    setHourList: any;
};

const AppointmentDateInput: React.FC<TAppointmentDateInputProps> = ({
    date,
    setDate,
    hour,
    setHour,
    hourList,
    setHourList,
}) => {
    const { t } = useTranslation();

    const { data: dealerSlotList } = useSelector(getDealerSlotListSelector);

    useEffect(() => {
        if (!date) setDate(dealerSlotList[0]?.date);
        const firstHour = hourList?.find((h) => h.status === 'open');
        setHour(firstHour?.id || '');
    }, [hourList, date, dealerSlotList]);

    useEffect(() => {
        if (dealerSlotList) {
            const hours = dealerSlotList.find((s) => s.date === date)?.hours;
            setHourList(hours || []);
        }
    }, [dealerSlotList, date]);

    const availableHours = hourList.filter((h) => h.status === 'open').length;

    return (
        <div className="mt-2">
            <Row>
                <Col xs="12" sm="5">
                    <Label>{t('appointment_date')}</Label>
                    <InputWithValidation>
                        <InputGroup>
                            <Input
                                type="select"
                                onChange={(e) => setDate(e.currentTarget.value)}
                                value={date}
                            >
                                {dealerSlotList.slice(0, 7).map((s) => (
                                    <option value={s.date} key={s.date}>
                                        {s.date}
                                    </option>
                                ))}
                            </Input>
                            <InputGroupAddon addonType="append">
                                <InputGroupText>
                                    <FontAwesomeIcon color="#10cfc9" icon={faCalendarAlt} />
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                        <InputValidation valid={!!date} />
                    </InputWithValidation>
                </Col>
                <Col xs="12" sm="5">
                    {hourList && (
                        <div className="hoursInput">
                            <Label>{t('appointment_slot')}</Label>
                            <InputWithValidation>
                                <InputGroup>
                                    <Input
                                        type="select"
                                        onChange={(e) => setHour(e.currentTarget.value)}
                                        value={hour}
                                    >
                                        <option value="">--</option>
                                        {hourList.map((s) => (
                                            <option
                                                value={s.id || ''}
                                                key={s.hour}
                                                disabled={s.status === 'closed'}
                                            >
                                                {s.hour}{' '}
                                                {s.status === 'closed'
                                                    ? `(${t('unavailable')})`
                                                    : ''}
                                            </option>
                                        ))}
                                    </Input>
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <FontAwesomeIcon color="#10cfc9" icon={faClock} />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                <InputValidation valid={!!hour} />
                            </InputWithValidation>
                        </div>
                    )}
                </Col>
            </Row>

            {availableHours < 6 && (
                <p className="available_slots_remaining">
                    {t('appointment_page_available_slots_remaining')
                        .replace('[NB de créneaux]', availableHours)
                        .replace(availableHours !== 1 ? /\[/g : '', '')
                        .replace(availableHours !== 1 ? /\]/g : '', '')
                        .replaceAll(availableHours === 1 ? '[s]' : '', '')}
                </p>
            )}
        </div>
    );
};

export default AppointmentDateInput;

import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
import { getClientSelector } from '../../view-models-generators/clientSelector';

const useTracker = (
    vehicle?: any,
    vehicleState?: any,
    particular?: any,
    displaySectionMoreDetails?: any,
    displaySectionAdditionalInformation?: any,
    privacyChecked?: any,
    canQuote?: any,
    submitting?: any,
    displaySectionPersonalDetails?: any, // todo later
) => {
    const { config } = useSelector(getClientSelector);

    const trackerPushEvent = (
        eventAction: string,
        eventLabel: string,
        reference = '',
        event = 'uaevent',
        eventCategory = 'Content',
    ) => {
        const message = { event, eventCategory, eventAction, eventLabel, reference };
        TagManager.dataLayer({ dataLayer: message });
    };

    const usePrevious = (value: any) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };

    const compareObject = (oldState: any, newState: any) => {
        const combined = { ...oldState, ...newState };
        const diff = Object.entries(combined).reduce((acc: any, [key, value]) => {
            if (newState[key] !== oldState[key]) acc[key] = value;
            return acc;
        }, {});
        return diff;
    };
    const prevVehicle = usePrevious(vehicle) as any;
    const prevVehicleState = usePrevious(vehicleState) as any;
    const prevParticular = usePrevious(particular) as any;

    // Tracking Step 1 - Vehicle
    useEffect(() => {
        const diffVehicle = compareObject(prevVehicle, vehicle);
        if (diffVehicle.make)
            trackerPushEvent('step1_choix_marque', 'form/information_de_base_choix_marque');
        if (diffVehicle.model)
            trackerPushEvent('step1_choix_modele', 'form/information_de_base_choix_modele');
        if (diffVehicle.month)
            trackerPushEvent(
                'step1_choix_mois_immatriculation',
                'form/information_de_base_choix_mois_immatriculation',
            );
        if (diffVehicle.year)
            trackerPushEvent(
                'step1_choix_annee_immatriculation',
                'form/information_de_base_choix_annee_immatriculation',
            );
        if (diffVehicle.fuel)
            trackerPushEvent('step1_choix_carburant', 'form/information_de_base_choix_carburant');
        if (diffVehicle.body) {
            trackerPushEvent(
                'step1_choix_type_de_voiture',
                'form/information_de_base_choix_type_voiture',
            );
        }

        if (diffVehicle.door) {
            trackerPushEvent('step2_portes', 'form/mes_details_portes');
        }

        if (diffVehicle.gear) {
            trackerPushEvent('step2_boite_vitesse', 'form/mes_details_boite_vitesse');
        }

        if (diffVehicle.fiscal) {
            trackerPushEvent('step2_choix_chevaux', 'form/information_chevaux_fiscaux');
        }

        if (diffVehicle.version) {
            trackerPushEvent('step2_version', 'form/mes_details_version');
        }

        if (diffVehicle.engine) {
            trackerPushEvent('step2_choix_puissance', 'form/information_de_base_choix_puissance');
        }

        if (diffVehicle.mileage) {
            trackerPushEvent('step2_kilometrage', 'form/mes_details_kilometrage');
        }
    }, [vehicle]);

    useEffect(() => {
        const diffVehicleState = compareObject(prevVehicleState, vehicleState);

        if (diffVehicleState.imported) {
            trackerPushEvent('step2_voiture_importée', 'form/mes_details_voiture_importée');
        }

        if (diffVehicleState.history) {
            trackerPushEvent('step2_carnet_entretien', 'form/mes_details_carnet_entretien');
        }

        if (diffVehicleState.running) {
            trackerPushEvent('step2_voiture_roulante', 'form/mes_details_voiture_roulante');
        }

        if (diffVehicleState.notRollingReason) {
            // trackerPushEvent('', '');
        }

        if (diffVehicleState.notRollingDescription) {
            // trackerPushEvent('', '');
        }

        if (diffVehicleState.purchaseProject) {
            trackerPushEvent('step2_projet_achat', 'form/mes_details_projet_achat');
        }

        if (diffVehicleState.firstHand) {
            trackerPushEvent('step2_première_main', 'form/mes_details_première_main');
        }
        if (diffVehicleState.serviceInvoice) {
            trackerPushEvent('step2_facture_entretien', 'form/mes_details_factures_entretien');
        }
    }, [vehicleState]);

    useEffect(() => {
        const diffParticular = compareObject(prevParticular, particular);

        if (diffParticular.email) {
            trackerPushEvent('step3_email', 'form/information_email');
            if (config.emailConfirmation)
                trackerPushEvent('step3_confirmation_email', 'form/information_confirmation_email');
        }

        if (diffParticular.zipCode) {
            trackerPushEvent('step3_code_postal', 'form/information_code_postal');
        }

        if (diffParticular.phone) {
            trackerPushEvent('step3_telephone', 'form/information_telephone');
        }
    }, [particular]);

    useEffect(() => {
        if (displaySectionMoreDetails) {
            trackerPushEvent('step1_validate', 'form/information_de_base');
        }
    }, [displaySectionMoreDetails]);

    useEffect(() => {
        if (displaySectionAdditionalInformation) {
            trackerPushEvent('step2_validate', 'form/mes_details');
        }
    }, [displaySectionAdditionalInformation]);

    // useEffect(() => {
    //     if (privacyChecked) {
    //         trackerPushEvent(
    //             'step3_condition_utilisation',
    //             'form/information_condition_utilisation',
    //         );
    //     }
    // }, [privacyChecked]);

    useEffect(() => {
        if (canQuote) {
            trackerPushEvent('step3_validate', 'form/information_additionnelle');
        }
    }, [canQuote]);

    useEffect(() => {
        if (submitting) {
            trackerPushEvent('step4_estimation_reprise', 'form/validation_informations');
        }
    }, [submitting]);

    return { trackerPushEvent, compareObject, usePrevious };
};

export default useTracker;

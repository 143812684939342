import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Container, Row } from 'reactstrap';
import _ from 'lodash';
import { TRecord } from '../../../../hexagon/interfaces';
import { AccordionInfo } from './AccordionInfo';
import useTranslation from '../hooks/useTranslation';
import { FeatureGroup } from './FeatureGroup';
import { Feature } from './Feature';
import { getDealerListSelector } from '../../view-models-generators/dealerSelectors';
import useScroll from '../hooks/useScroll';
import { getClientSelector } from '../../view-models-generators/clientSelector';
import { TextUtils } from '../../../../hexagon/shared/utils/TextUtils';
import { Loader } from './Loader';

export const AppointmentNoPOS: React.FC<TRecord> = (props) => {
    const { t } = useTranslation();
    const { scrollToElement } = useScroll();
    const { client } = useSelector(getClientSelector);
    const { locale, currency } = client.config;
    const { vehicle, valuation, offerNumber } = props;

    const { status: dealerListStatus } = useSelector(getDealerListSelector);

    return (
        <div className="page page-valuation">
            <Container fluid className="px-0 pt-4">
                <div className="text-center">
                    <h2 className="valuation-title">
                        {(t('appointment_page_valuation_bloc_title') || '')
                            .replace('[VO_marque]', vehicle.makeName)
                            .replace('[VO_modele]', vehicle.modelName)}
                    </h2>
                    {valuation && (
                        <div className="valuation-value">
                            {TextUtils.formatPrice(locale, currency, valuation.value)}
                        </div>
                    )}

                    <p className="valuation-description">{t('valuation_detail')}</p>

                    <p className="valuation-reference">
                        {t('reference_id')} <strong>{offerNumber}</strong>
                    </p>
                </div>

                <FeatureGroup
                    title={
                        <div className="h1 p-2">{t('appointment_page_marketing_bloc_title')}</div>
                    }
                >
                    <Feature label="appointment_page_argument_1" icon="map" />
                    <Feature label="appointment_page_argument_2" icon="clock" />
                    <Feature label="appointment_page_argument_3" icon="check" />
                </FeatureGroup>

                <Row className="m-3 text-center option-workflow">
                    <Col xs={12} sm={12}>
                        <Button
                            color="primary"
                            onClick={() => scrollToElement('appointment_page_bloc_1', 45)}
                            className="rad-cta"
                        >
                            {t('appointment_page_cta_appointment')}
                        </Button>
                    </Col>
                </Row>

                <div className="form-section" id="appointment_page_bloc_1">
                    <Loader status={dealerListStatus}>
                        <div className="form-section-title">
                            {t('appointment_page_bloc_1_title')}
                        </div>

                        <AccordionInfo
                            iconType="circle"
                            titleKey="appointment_page_bloc_1_question_1"
                            detailsKey="appointment_page_bloc_1_answer_1"
                        />
                        <AccordionInfo
                            iconType="circle"
                            titleKey="appointment_page_bloc_1_question_2"
                            detailsKey="appointment_page_bloc_1_answer_2"
                        />
                        <p className="no-pos-description">{t('appointment_page_no_pos')}</p>
                    </Loader>
                </div>
            </Container>
        </div>
    );
};

import { isLeft } from 'fp-ts/lib/Either';
import { ThunkResult } from '../../../redux/configureStore';
import { DealerGateway } from '../../gateways/dealerGateway.interface';
import { RecordGateway } from '../../gateways/recordGateway.interface';
import { dislayErrorUseCase } from '../displayError/displayError.useCase';
import { getDealerListUseCase } from '../getDealerList/getDealerList.useCase';
import { getRecordUseCase } from '../getRecord/getRecord.useCase';
import { updateDealerListUseCase } from '../updateDealerList/updateDealerList.useCase';
import * as actionCreators from './actionCreators';

export const saveRecordPSAInformationUseCase =
    (psaRecordUid: string): ThunkResult<void> =>
    async (
        dispatch,
        getState,
        { recordGateway }: { recordGateway: RecordGateway; dealerGateway: DealerGateway },
    ) => {
        dispatch(actionCreators.Actions.saveRecordPSAInformationsSaving());

        const { config, journeyType, origin } = getState().client;

        // Create new record from psa record data
        const resultPSARecord = await recordGateway.saveRecordPSAInformation(
            config.identifier,
            psaRecordUid,
            journeyType,
            origin === 'StellantisYou' ? origin : undefined,
        );

        if (isLeft(resultPSARecord)) {
            dispatch(dislayErrorUseCase('create_psa_record_failed'));
            return dispatch(actionCreators.Actions.saveRecordPSAInformationsFailed());
        }

        const recordUid = resultPSARecord.right.uid;

        // create quotation
        let quotationStatus = false;

        const resultQuotation = await recordGateway.createQuotation(config.identifier, recordUid);

        if (isLeft(resultQuotation)) {
            return dispatch(
                dislayErrorUseCase(
                    'Desafortunadamente no podemos darte una valoración por tu coche',
                ),
            ); // create_quotation_failed
        }

        if (resultQuotation.right) {
            quotationStatus = true;
        }

        if (quotationStatus) {
            await dispatch(getDealerListUseCase(recordUid));
            await dispatch(updateDealerListUseCase());

            const { status: dealerListStatus } = getState().dealer.dealerList;

            if (dealerListStatus) {
                const { isRad } = getState().record.data;
                const { closePOS } = getState();

                await recordGateway.sendMail(
                    config.identifier,
                    recordUid,
                    isRad && !closePOS
                        ? 'restitution_mail_with_hbs'
                        : 'restitution_mail_without_hbs',
                );
            } else {
                await recordGateway.sendMail(config.identifier, recordUid, 'empty_network_mail');
            }
        } else {
            await recordGateway.sendMail(config.identifier, recordUid, 'cq_mail');
        }

        await dispatch(getRecordUseCase(recordUid));

        return dispatch(
            actionCreators.Actions.saveRecordPSAInformationsSaved(resultPSARecord.right),
        );
    };

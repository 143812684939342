import { isRight } from 'fp-ts/lib/Either';

import { ThunkResult } from '../../../redux/configureStore';
import { ReferentialGateway } from '../../gateways/referentialGateway.interface';
import { TReferentialItem } from '../../interfaces';
import { requestListUseCase } from '../setVehicleValue/setVehicleValue.useCase';
import * as actionCreators from './actionCreators';
import * as setVehiculeValueActionCreators from '../setVehicleValue/actionCreators';

export const getCarDetailsByRegistrationUseCase =
    (registration: string): ThunkResult<void> =>
    async (
        dispatch,
        getState,
        { referentialGateway }: { referentialGateway: ReferentialGateway },
    ) => {
        dispatch(actionCreators.Actions.carDetailsLoading());
        const { identifier } = getState().client.config;
        const { cascade } = getState().form;

        const result = await referentialGateway.requestCartDetailsByRegsitration(
            identifier,
            registration,
        );

        if (isRight(result)) {
            const {
                make,
                makeName,
                model,
                modelName,
                year,
                month,
                monthName,
                fuel,
                fuelName,
                body,
                bodyName,
                door,
                gear,
                gearName,
                engine,
                grayCardDate,
            } = result.right;

            dispatch(
                actionCreators.Actions.setVehicleAll({
                    make,
                    model,
                    year,
                    month,
                    door,
                    fuel,
                    body,
                    engine,
                    gear,
                    grayCardDate,
                }),
            );
            dispatch(
                actionCreators.Actions.setVehicleNames({
                    make: makeName,
                    model: modelName,
                    year,
                    month: monthName,
                    fuel: fuelName.toUpperCase(),
                    body: bodyName,
                    door,
                    gear: gearName,
                    engine,
                }),
            );

            let key: TReferentialItem | null = null;
            if (!year) key = 'year';
            else if (!month) key = 'month';
            else if (!fuel) key = 'fuel';
            else if (!body) key = 'body';
            else if (!door) key = 'door';
            else if (!engine) key = 'engine';
            else if (!gear) key = 'gear';

            if (key) {
                dispatch(requestListUseCase(key));
                let reset = false;
                cascade.forEach((element) => {
                    if (reset) {
                        dispatch(
                            actionCreators.Actions.setVehicleName({
                                key: element,
                                value: '',
                            }),
                        );
                        dispatch(
                            setVehiculeValueActionCreators.Actions.setVehicle({
                                key: element,
                                value: '',
                            }),
                        );
                    }

                    if (!reset && element === key) reset = true;
                });
            } else dispatch(requestListUseCase('version'));

            dispatch(actionCreators.Actions.carDetailsRetrieved());
        } else {
            dispatch(actionCreators.Actions.carDetailsFailed());
        }
    };

import React from 'react';
import { Col, Row } from 'reactstrap';
import useTranslation from '../hooks/useTranslation';
import iphone from '../Themes/spoticar/img/iphone.png';

const HowToWorkSection = () => {
    const { t } = useTranslation();

    return (
        <section className="hbs-page-service">
            <h1 className="text-center mb-5">{t('hbs_page_service_explanation_title')}</h1>
            <Row>
                <Col xs={12} sm={5} md={5} lg={3} className="text-center mb-4">
                    <img src={iphone} alt="rachat à domicile Spoticar" />
                </Col>
                <Col xs={12} sm={7}>
                    <Row>
                        <Col xs={1}>
                            <div className="hbs-page-service-number">1</div>
                        </Col>
                        <Col xs={11}>
                            <h3 className="service-explanation">
                                {t('hbs_page_service_explanation_step_1')}
                            </h3>
                            <p className="pr-4">
                                {t('hbs_page_service_explanation_step_1_detail')}
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={1}>
                            <div className="hbs-page-service-number">2</div>
                        </Col>
                        <Col xs={11}>
                            <h3 className="service-explanation">
                                {t('hbs_page_service_explanation_step_2')}
                            </h3>
                            <p className="pr-4">
                                {t('hbs_page_service_explanation_step_2_detail')}
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={1}>
                            <div className="hbs-page-service-number">3</div>
                        </Col>
                        <Col xs={11}>
                            <h3 className="service-explanation">
                                {t('hbs_page_service_explanation_step_3')}
                            </h3>

                            <p
                                className="pr-4"
                                dangerouslySetInnerHTML={{
                                    __html: t('hbs_page_service_explanation_step_3_detail') || '',
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    );
};

export default HowToWorkSection;

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Container, Row } from 'reactstrap';
import _ from 'lodash';
import { Hour, TRecord } from '../../../../hexagon/interfaces';
import { AccordionInfo } from './AccordionInfo';
import useTranslation from '../hooks/useTranslation';
import { FeatureGroup } from './FeatureGroup';
import { Feature } from './Feature';
import { PhoneInput } from './PhoneInput';
import { Picture } from './Picture';
import AppointmentDateInput from './AppointmentDateInput';
import { NameInput } from './NameInput';
import { AppointmentResume } from './AppointmentResume';
import {
    getDealerListSelector,
    getDealerSlotListSelector,
} from '../../view-models-generators/dealerSelectors';
import DealerComponent from './DealerComponent';
import { getFormSelector } from '../../view-models-generators/formSelectors';
import { getDealerSlotListUseCase } from '../../../../hexagon/usecases/getDealerSlotList/getDealerSlotList.useCase';
import useScroll from '../hooks/useScroll';
import { saveAppointmentUseCase } from '../../../../hexagon/usecases/saveAppointment/saveAppointment.useCase';
import HbsPhoneInput from './HbsPhoneInput';
import { getRecordSelector } from '../../view-models-generators/recordSelectors';
import { setCancellationReasonValueUseCase } from '../../../../hexagon/usecases/setCancellationReasonValue/setCancellationReasonValue.useCase';
import { getClientSelector } from '../../view-models-generators/clientSelector';
import { TextUtils } from '../../../../hexagon/shared/utils/TextUtils';
import { Loader } from './Loader';
import { getSaveAppointmentStatus } from '../../view-models-generators/saveAppointmentSelector';
import { getisPostponedSelector } from '../../view-models-generators/isPostponedSelector';
import { reportAppointmentUseCase } from '../../../../hexagon/usecases/reportAppointment/reportAppointment.useCase';
import { isPostponedUseCase } from '../../../../hexagon/usecases/setPostpone/setPostpone.useCase';

export const HbsAppointment: React.FC<TRecord> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { scrollToElement } = useScroll();
    const { client } = useSelector(getClientSelector);
    const { locale, currency } = client.config;
    const { vehicle, valuation, uid, offerNumber } = props;

    const [dealer, setDealer] = useState<{ id: string; name: string }>({ id: '', name: '' });
    const [showAllDealers, setShowAllDealers] = useState<boolean>(false);
    const [date, setDate] = useState<string>('');
    const [hour, setHour] = useState<string>('');
    const [hourList, setHourList] = useState<Hour[]>([]);

    const { data: dealerList, status: dealerListStatus } = useSelector(getDealerListSelector);
    const { status: dealerSlotStatus } = useSelector(getDealerSlotListSelector);
    const { particular } = useSelector(getFormSelector);

    const formValid = [hour, date, dealer.id, particular.name, particular.phone2].every(Boolean);
    const {
        data: { customer },
    } = useSelector(getRecordSelector);

    const { status: saveAppointmentStatus } = useSelector(getSaveAppointmentStatus);

    useEffect(() => {
        if (dealer?.id) {
            dispatch(getDealerSlotListUseCase(uid, dealer.id));
            setHour('');
            scrollToElement('appointment_page_bloc_2', 45);
        }
    }, [dispatch, uid, dealer]);
    const isPostponed = useSelector(getisPostponedSelector);

    const submitForm = () => {
        dispatch(setCancellationReasonValueUseCase({}));
        if (isPostponed) {
            dispatch(reportAppointmentUseCase(uid, hour));
            dispatch(isPostponedUseCase(false));
        } else if (saveAppointmentStatus !== 'pending') dispatch(saveAppointmentUseCase(uid, hour));
    };

    return (
        <div className="page page-valuation">
            <Container fluid className="px-0 pt-4">
                <div className="text-center">
                    <h2 className="valuation-title">
                        {(t('appointment_page_valuation_bloc_title') || '')
                            .replace('[VO_marque]', vehicle.makeName)
                            .replace('[VO_modele]', vehicle.modelName)}
                    </h2>
                    {valuation && (
                        <div className="valuation-value">
                            {TextUtils.formatPrice(locale, currency, valuation.value)}
                        </div>
                    )}

                    <p className="valuation-description">{t('valuation_detail')}</p>

                    <p className="valuation-reference">
                        {t('reference_id')} <strong>{offerNumber}</strong>
                    </p>
                </div>

                <FeatureGroup
                    title={
                        <div className="h1 p-2">{t('appointment_page_marketing_bloc_title')}</div>
                    }
                >
                    <Feature label="appointment_page_argument_1" icon="map" />
                    <Feature label="appointment_page_argument_2" icon="clock" />
                    <Feature label="appointment_page_argument_3" icon="check" />
                </FeatureGroup>

                <Row className="m-3 text-center option-workflow">
                    <Col xs={12} sm={5}>
                        <Button
                            color="primary"
                            onClick={() => scrollToElement('appointment_page_bloc_1', 45)}
                        >
                            {t('appointment_page_cta_appointment')}
                        </Button>
                    </Col>

                    <Col xs={12} sm={2} className="text-center my-auto ">
                        <div className="or">{t('or')}</div>
                    </Col>

                    <Col xs={12} sm={5}>
                        <Button color="primary" onClick={() => scrollToElement('bloc-hbs', 45)}>
                            {t('appointment_page_cta_hbs')}
                        </Button>
                    </Col>
                </Row>

                {/* <div className="alert-covid">
                    <AccordionInfo
                        iconType="triangle"
                        titleKey="appointment_page_important_information"
                        detailsKey="appointment_page_important_information_details"
                    />
                </div> */}

                <div className="form-section" id="appointment_page_bloc_1">
                    <Loader status={dealerListStatus}>
                        <div className="form-section-title">
                            {t('appointment_page_bloc_1_title')}
                        </div>

                        <AccordionInfo
                            iconType="circle"
                            titleKey="appointment_page_bloc_1_question_1"
                            detailsKey="appointment_page_bloc_1_answer_1"
                        />
                        <AccordionInfo
                            iconType="circle"
                            titleKey="appointment_page_bloc_1_question_2"
                            detailsKey="appointment_page_bloc_1_answer_2"
                        />

                        <div className={`dealers-list ${showAllDealers ? 'show-all' : ''}`}>
                            {dealerList.slice(0, 2).map((d) => (
                                <DealerComponent
                                    key={d.id}
                                    pointOfSale={d}
                                    dealer={dealer}
                                    setDealer={setDealer}
                                />
                            ))}

                            <div className="bloc-hbs" id="bloc-hbs">
                                <Row className="m-1">
                                    <Row>
                                        <Col>
                                            <span className="text-new">{t('new')}</span>{' '}
                                            <span>{t('appointment_page_hbs_title')}</span>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col xs={6} className="bloc">
                                            <p className="font-weight-bold">
                                                {t('appointment_page_hbs_guidelines')}
                                            </p>
                                            <HbsPhoneInput customerPhone={customer.phone} />
                                        </Col>
                                        <Col xs={6} className="bloc">
                                            <Row>
                                                <Col>
                                                    <Row>
                                                        <Col xs={1} className="mt-1">
                                                            <Picture background="checkbox" />
                                                        </Col>
                                                        <Col xs={11}>
                                                            <p>
                                                                {t(
                                                                    'appointment_page_hbs_argument_1',
                                                                )}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={1} className="mt-1">
                                                            <Picture background="checkbox" />
                                                        </Col>
                                                        <Col xs={11}>
                                                            <p>
                                                                {t(
                                                                    'appointment_page_hbs_argument_2',
                                                                )}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={1} className="mt-1">
                                                            <Picture background="checkbox" />
                                                        </Col>
                                                        <Col xs={11}>
                                                            {/* Lien à venir prochainement */}
                                                            <p
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        t(
                                                                            'appointment_page_hbs_argument_3',
                                                                        ) || '',
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Row>
                            </div>

                            {dealerList.slice(2, 4).map((d) => (
                                <DealerComponent
                                    key={d.id}
                                    pointOfSale={d}
                                    dealer={dealer}
                                    setDealer={setDealer}
                                />
                            ))}

                            {!showAllDealers && dealerList.length > 4 && (
                                <div
                                    className="toggle-show-dealers"
                                    role="button"
                                    aria-hidden="true"
                                    onClick={() => setShowAllDealers(!showAllDealers)}
                                >
                                    <FontAwesomeIcon
                                        color="#10cfc9"
                                        icon={showAllDealers ? faMinusCircle : faPlusCircle}
                                    />{' '}
                                    <span>{t('appointment_page_show_more_pos')}</span>
                                </div>
                            )}

                            {showAllDealers &&
                                dealerList
                                    .slice(4, 7)
                                    .map((d) => (
                                        <DealerComponent
                                            key={d.id}
                                            pointOfSale={d}
                                            dealer={dealer}
                                            setDealer={setDealer}
                                        />
                                    ))}
                        </div>
                    </Loader>
                </div>

                {dealer.id && (
                    <div className="form-section" id="appointment_page_bloc_2">
                        <div className="form-section-title">
                            {t('appointment_page_bloc_2_title')}
                        </div>

                        <AccordionInfo
                            iconType="circle"
                            titleKey="appointment_page_bloc_2_question_1"
                            detailsKey="appointment_page_bloc_2_answer_1"
                        />
                        <AccordionInfo
                            iconType="circle"
                            titleKey="appointment_page_bloc_2_question_2"
                            detailsKey="appointment_page_bloc_2_answer_2"
                        />
                        <Loader status={dealerSlotStatus}>
                            <AppointmentDateInput
                                date={date}
                                setDate={setDate}
                                hour={hour}
                                setHour={setHour}
                                hourList={hourList}
                                setHourList={setHourList}
                            />
                        </Loader>
                    </div>
                )}

                {hour && date && (
                    <div className="form-section" id="appointment_page_bloc_3">
                        <div className="form-section-title">
                            {t('appointment_page_bloc_3_title')}
                        </div>

                        <Row>
                            <Col xs={12} sm={5}>
                                <NameInput />
                            </Col>
                            <Col xs={12} sm={5}>
                                <PhoneInput scope="phone2" required color="#10cfc9" />
                            </Col>
                        </Row>
                        <Row>
                            <Button
                                color="primary"
                                size="lg"
                                disabled={!formValid}
                                className="mt-3"
                                onClick={submitForm}
                            >
                                {t('appointment_page_appointment_confirmation_cta')}
                            </Button>
                        </Row>

                        {formValid && (
                            <>
                                <Row className="mt-4">
                                    <Col xs={12} sm={9}>
                                        <div className="h1">{t('appointment_recap_title')}</div>
                                        <AppointmentResume
                                            date={date}
                                            hour={
                                                _.find(hourList, (o) => o.id === hour)?.hour || ''
                                            }
                                        />
                                    </Col>
                                </Row>
                                <p
                                    className="footnote"
                                    dangerouslySetInnerHTML={{
                                        __html: t('appointment_page_gdpr') || '',
                                    }}
                                />
                            </>
                        )}
                    </div>
                )}

                <FeatureGroup>
                    <Feature
                        label="appointment_page_argument_4"
                        icon="clock"
                        details="appointment_page_detail_argument_4"
                    />
                    <Feature
                        label="appointment_page_argument_5"
                        icon="lock"
                        details="appointment_page_detail_argument_5"
                    />
                    <Feature
                        label="appointment_page_argument_6"
                        icon="check"
                        details="appointment_page_detail_argument_6"
                    />
                </FeatureGroup>
            </Container>
        </div>
    );
};

import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import useTranslation from '../hooks/useTranslation';
import { getClientSelector } from '../../view-models-generators/clientSelector';
import expertPicture from '../Themes/spoticar/img/expert_img.png';

type TDealerComponentProps = {
    pointOfSale: any;
    dealer: any;
    setDealer: any;
};

const DealerComponent: React.FC<TDealerComponentProps> = ({ pointOfSale, dealer, setDealer }) => {
    const { t } = useTranslation();
    const { client } = useSelector(getClientSelector);

    const addDefaultSrc = (target: any) => {
        target.src = expertPicture;
    };

    return (
        <div
            className={`button-dealer ${pointOfSale.id === dealer.id ? 'selected' : ''}`}
            key={pointOfSale.id}
            role="button"
            aria-hidden="true"
            onClick={() => {
                setDealer({
                    id: pointOfSale.id,
                    name: pointOfSale.name,
                });
            }}
        >
            <div className="button-dealer-distance">
                <FontAwesomeIcon icon={faMapMarkerAlt} /> {pointOfSale.distance} km
            </div>
            <div className="button-dealer-icon">
                <input
                    id={`radio-${pointOfSale.id}`}
                    className="radio-custom"
                    name="radio-group"
                    type="radio"
                />
                <label htmlFor={`radio-${pointOfSale.id}`} className="radio-custom-label" />
            </div>
            <div className="button-dealer-avatar">
                <img
                    onError={(e) => addDefaultSrc(e.target)}
                    src={
                        pointOfSale.experts[0].picture
                            ? pointOfSale.experts[0].picture
                            : expertPicture
                    }
                    className="userimg"
                    alt=""
                />
            </div>
            <div>
                <div className="button-dealer-expert">{pointOfSale.experts[0].firstName} </div>
                <div className="button-dealer-name">
                    {t('point_of_sale')} {pointOfSale.c2bName}{' '}
                </div>
                <div className="button-dealer-review">
                    {pointOfSale.totalReviewers && (
                        <div>
                            {pointOfSale.totalReviewers} {t('review')}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DealerComponent;

import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, FormGroup, Input, InputGroup, Row } from 'reactstrap';
import useTranslation from '../hooks/useTranslation';
import { getClientSelector } from '../../view-models-generators/clientSelector';
import { submitRadApplicationUseCase } from '../../../../hexagon/usecases/submitRadApplication/submitRadApplication.useCase';
import { getSmsSentSelector } from '../../view-models-generators/smsSentSelector';

type TPhoneInputProps = {
    customerPhone?: string;
};
const HbsPhoneInput: React.FC<TPhoneInputProps> = ({ customerPhone }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { client } = useSelector(getClientSelector);
    const { status } = useSelector(getSmsSentSelector);

    const [phone, setPhone] = useState<string>(customerPhone || '');
    const [phoneValid, setPhoneValid] = useState<boolean | undefined>(undefined);

    const checkForm = () => {
        if (phone) {
            setPhoneValid(phone.search(new RegExp(client.config.phoneRadRegex)) === 0);
        } else {
            setPhoneValid(undefined);
        }
    };

    useEffect(() => {
        // if the phone has the corecct length, we don't need to focus outside the input to check it
        if (phone.length === 9) checkForm();
    }, [phone]);

    const handleSubmit = () => {
        if (phoneValid && status !== 'pending') dispatch(submitRadApplicationUseCase(phone));
    };

    useEffect(() => {
        if (status === 'succeeded') {
            history.push('/confirmation');
        }
    }, [dispatch, status]);

    const handleChange = (value: string) => {
        if (value.length < 10) {
            setPhone(value.replace(/[^0-9]/g, ''));
            setPhoneValid(undefined);
        }
    };
    return (
        <FormGroup>
            <Row>
                <Col xs={11}>
                    <div className="input-with-validation">
                        <InputGroup>
                            <Input
                                className={`input-rad  border ${
                                    phoneValid === false && phone !== '' ? 'border-danger' : ''
                                }`}
                                type="tel"
                                id="phone"
                                name="phone"
                                value={phone}
                                placeholder={t('phone_field')}
                                onChange={(e) => handleChange(e.currentTarget.value)}
                                onBlur={checkForm}
                            />
                        </InputGroup>

                        {!_.isUndefined(phone) && (
                            <div className="input-validation input-validation-tel input-validation-tel-rad">
                                {phoneValid === true && <FontAwesomeIcon icon={faCheck} />}
                                {phoneValid === false && <FontAwesomeIcon icon={faTimes} />}
                            </div>
                        )}
                    </div>
                </Col>
                <Col xs={11}>
                    <Button
                        color="primary"
                        onClick={handleSubmit}
                        disabled={!phoneValid}
                        className="mt-2 w-100"
                    >
                        {t('appointment_page_hbs_cta')}
                    </Button>
                </Col>
            </Row>
        </FormGroup>
    );
};

export default HbsPhoneInput;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Button, Spinner } from 'reactstrap';
import { useHistory } from 'react-router';
import { ProgressSteps } from './ProgressSteps';
import { ReferentialSelect } from './ReferentialSelect';
import { Message } from './Message';
import { getFormSelector } from '../../view-models-generators/formSelectors';
import { MileageInput } from './MileageInput';
import { ButtonRadioInput } from './ButtonRadioInput';
import { CtaBlock } from './CtaBlock';
import { ZipCodeInput } from './ZipCodeInput';
import { PhoneInput } from './PhoneInput';
import { EmailInput } from './EmailInput';
import { MakeLogoInput } from './MakeLogoInput';
import { saveVehicleAndUserInformationsUseCase } from '../../../../hexagon/usecases/saveVehicleAndUserInformation/saveVehicleAndUserInformations.useCase';
import { getRecordSelector } from '../../view-models-generators/recordSelectors';
import { getReferentialList } from '../../../../hexagon/usecases/getReferentialList/getReferentialList';
import { RegistrationInput } from './RegistrationInput';
import { setCascade } from '../../../../hexagon/usecases/setVehicleValue/setVehicleValue.useCase';
import { getClientSelector } from '../../view-models-generators/clientSelector';
import { buyingProject } from '../../../../config';
import useScroll from '../hooks/useScroll';
import useTranslation from '../hooks/useTranslation';
import useTracker from '../hooks/useTracker';
import { SimpleInput } from './SimpleInput';
import ValuationLoader from './ValuationLoader';
import { MakeSelectInput } from './MakeSelectInput';
import { setVehicleStateValue } from '../../../../hexagon/usecases/setVehicleStateValue/setVehicleStateValue.useCase';
import NewVehiclePurchaseLoader from './NewVehiculePurchaseLoader';
import { setBuyingProjectMakeUseCase } from '../../../../hexagon/usecases/setBuyingProjectMake/setBuyingProjectMake.useCase';
import { getBuyingProjectMakeSelector } from '../../view-models-generators/buyingProjectMakeSelector';

export const FormVehicle: React.FC = () => {
    const dispatch = useDispatch();
    const historyHook = useHistory();
    const { scrollToElement } = useScroll();
    const { t } = useTranslation();
    const {
        vehicle,
        vehicleState,
        particular,
        checkFormValid,
        checkZipCode,
        identificationType,
        registration,
    } = useSelector(getFormSelector);
    const { journeyType, config } = useSelector(getClientSelector).client;
    const { uid: recordUid, status: recordStatus } = useSelector(getRecordSelector);
    const { name: buyingProjectMake } = useSelector(getBuyingProjectMakeSelector);

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>({});
    const [canQuote, setCanQuote] = useState<boolean>(false);
    const [privacyChecked, setPrivacyChecked] = useState<boolean>(true);
    const [trySubmit, setTrySubmit] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [displaySectionMoreDetails, setDisplaySectionMoreDetails] = useState<boolean>(false);
    const [displaySectionAdditionalInformation, setDisplaySectionAdditionalInformation] =
        useState<boolean>(false);
    const [displaySectionPersonalDetails, setDisplaySectionPersonalDetails] =
        useState<boolean>(false);

    let displayEncouragementVersion = false;
    let displayEncouragementEmail = false;

    const isWorkflowActif = ['brandModel', 'registration'].includes(identificationType);

    useTracker(
        vehicle,
        vehicleState,
        particular,
        displaySectionMoreDetails,
        displaySectionAdditionalInformation,
        privacyChecked,
        canQuote,
        submitting,
        displaySectionPersonalDetails,
    );
    if (vehicle.engine) displayEncouragementVersion = true;
    if (vehicleState.running) displayEncouragementEmail = true;

    const handleSubmitForm = () => {
        if (canQuote) {
            dispatch(saveVehicleAndUserInformationsUseCase());
            setSubmitting(true);
            setCanQuote(false);
        }

        setTrySubmit(true);
    };

    useEffect(() => {
        dispatch(
            setCascade([
                'make',
                'model',
                'month',
                'year',
                'fuel',
                'body',
                'door',
                'gear',
                'engine',
                'version',
            ]),
        );

        dispatch(getReferentialList('make'));
    }, [dispatch]);

    useEffect(() => {
        const basicInformations = [
            vehicle.make,
            vehicle.model,
            vehicle.month,
            vehicle.year,
            vehicle.fuel,
            vehicle.body,
        ];

        const moreDetails = [
            vehicle.door,
            vehicle.gear,
            vehicle.engine,
            vehicle.version,
            vehicle.mileage,
            vehicleState.running,
        ];

        const additionalDetails = [
            vehicleState.imported,
            vehicleState.history,
            vehicleState.firstHand,
            vehicleState.serviceInvoice,
            vehicleState.purchaseProject,
        ];

        const personalDetails = [particular.email, particular.zipCode];

        setDisplaySectionMoreDetails(basicInformations.every(Boolean) && isWorkflowActif);

        setDisplaySectionAdditionalInformation(
            [displaySectionMoreDetails, ...moreDetails].every(Boolean) && isWorkflowActif,
        );

        setDisplaySectionPersonalDetails(
            [displaySectionAdditionalInformation, ...additionalDetails].every(Boolean) &&
                isWorkflowActif,
        );

        let checkVoi = true;
        if (vehicleState.running === 'no') {
            checkVoi = vehicleState.notRollingDescription && vehicleState.notRollingReason;
        }

        let checkMakeNewCar = true;
        if (Number(vehicleState.purchaseProject) === 6) {
            checkMakeNewCar = vehicleState.buyingProjectMake;
        }

        setCanQuote(
            [
                displaySectionMoreDetails,
                ...moreDetails,
                ...additionalDetails,
                ...personalDetails,
            ].every(Boolean) &&
                checkFormValid &&
                checkZipCode &&
                checkVoi &&
                privacyChecked &&
                checkMakeNewCar &&
                vehicle.mileage > 100,
        );

        if (isWorkflowActif) {
            setProgress(
                (((basicInformations.filter(Boolean).length / basicInformations.length) * 1) / 9) *
                    100 +
                    (((moreDetails.filter(Boolean).length / moreDetails.length) * 1) / 9) * 100 +
                    (((additionalDetails.filter(Boolean).length / additionalDetails.length) * 1) /
                        9) *
                        100 +
                    (((personalDetails.filter(Boolean).length / personalDetails.length) * 1) / 3) *
                        100,
            );
        } else setProgress(0);
    }, [
        dispatch,
        vehicle,
        vehicleState,
        particular,
        checkZipCode,
        checkFormValid,
        privacyChecked,
        isWorkflowActif,
    ]);

    useEffect(() => {
        if (
            recordUid &&
            recordStatus === 'saved' &&
            !(buyingProjectMake && vehicleState.running === 'yes')
        ) {
            historyHook.push(`./record/${recordUid}`);
        }
    }, [dispatch, recordUid, recordStatus, journeyType, historyHook]);

    useEffect(() => {
        if (isWorkflowActif) {
            if (!vehicle.make && trySubmit) scrollToElement('form_group_make', 45);
            else if (vehicle.model === '') scrollToElement('form_group_model', 45);
            else if (vehicle.month === '') scrollToElement('form_group_month', 45);
            else if (vehicle.year === '') scrollToElement('form_group_month', 45);
            else if (vehicle.fuel === '') scrollToElement('form_group_fuel', 45);
            else if (vehicle.body === '') scrollToElement('form_group_body', 45);
            else if (vehicle.door === '') scrollToElement('form_group_door', 45);
            else if (vehicle.gear === '') scrollToElement('form_group_gear', 45);
            else if (vehicle.engine === '') scrollToElement('form_group_engine', 45);
            else if (vehicle.version === '') scrollToElement('form_group_version', 45);
            else if (vehicle.engine && !vehicle.version) scrollToElement('form_group_version', 45);
            else if (vehicle.version && !vehicle.mileage) scrollToElement('form_group_mileage', 45);
            else if (vehicle.mileage && !vehicleState.running)
                scrollToElement('form_group_running', 45);
            else if (vehicleState.running === 'yes' && !vehicleState.imported)
                scrollToElement('additional_information', 45);
            else if (vehicleState.running === 'no' && !vehicleState.notRollingReason)
                scrollToElement('form_group_notRollingReason', 45);
            else if (
                vehicleState.running === 'no' &&
                vehicleState.notRollingReason &&
                !vehicleState.notRollingDescription
            )
                scrollToElement('form_group_notRollingDescription', 45);
            else if (vehicleState.notRollingDescription && !vehicleState.imported)
                scrollToElement('form_group_imported', 45);
            else if (vehicleState.imported && !vehicleState.firstHand)
                scrollToElement('form_group_firstHand', 45);
            else if (vehicleState.firstHand && !vehicleState.history)
                scrollToElement('form_group_history', 45);
            else if (vehicleState.history && !vehicleState.serviceInvoice)
                scrollToElement('form_group_serviceInvoice', 45);
            else if (vehicleState.serviceInvoice && !vehicleState.purchaseProject)
                scrollToElement('form_group_purchaseProject', 45);
            else if (Number(vehicleState.purchaseProject) === 6 && !vehicleState.buyingProjectMake)
                scrollToElement('form_group_buyingProjectMake', 45);
            else if (!particular.email) scrollToElement('personal_information', 45);
            // else if (!particular.zipCode) scrollToElement('form_group_zipCode' );

            if (submitting) {
                scrollToElement('page-index', 45);
                scrollToElement('spinner-loader', 45);
            }
        }
        setTrySubmit(false);
    }, [
        dispatch,
        vehicle,
        vehicleState,
        particular,
        scrollToElement,
        trySubmit,
        submitting,
        isWorkflowActif,
    ]);

    useEffect(() => {
        if (trySubmit) {
            setErrors({
                model: !vehicle.model,
                month: !vehicle.month,
                year: !vehicle.year,
                version: !vehicle.version,
                fuel: !vehicle.fuel,
                body: !vehicle.body,
                door: !vehicle.door,
                gear: !vehicle.gear,
                engine: !vehicle.engine,
                mileage: !vehicle.mileage,
                import: !vehicleState.imported,
                history: !vehicleState.history,
                running: !vehicleState.running,
                notRollingReason: vehicleState.running === 'no' && !vehicleState.notRollingReason,
                notRollingDescription:
                    vehicleState.running === 'no' && !vehicleState.notRollingDescription,
                email: !particular.email,
                zipCode: !particular.zipCode,
                privacy: !privacyChecked,
                firstHand: !vehicleState.firstHand,
                serviceInvoice: !vehicleState.serviceInvoice,
                purchaseProject: !vehicleState.purchaseProject,
                buyingProjectMake:
                    Number(vehicleState.purchaseProject) === 6 && !vehicleState.buyingProjectMake,
            });
        }
    }, [dispatch, trySubmit, vehicle]);

    useEffect(() => {
        if (Number(vehicleState.purchaseProject) !== 6) {
            dispatch(setVehicleStateValue('buyingProjectMake'));
            dispatch(setBuyingProjectMakeUseCase({}));
        }
    }, [dispatch, vehicleState.purchaseProject]);

    useEffect(() => {
        if (registration) {
            scrollToElement('top', 45);
        }
    }, [registration]);

    if (submitting) {
        if (vehicleState.running === 'no')
            return (
                <div className="text-center m-5 spinner-loader" id="spinner-loader">
                    <Spinner />
                </div>
            );
        return (
            <div className="page page-index" id="page-index">
                {buyingProjectMake ? <NewVehiclePurchaseLoader /> : <ValuationLoader />}
            </div>
        );
    }

    return (
        <div className="page page-index" id="top">
            <Container fluid>
                <Row className="progress-bar-section">
                    <Col xs={12} sm={9}>
                        <ProgressSteps progress={progress} withLabels />
                    </Col>
                </Row>
                <div className="form-section">
                    <div className="form-title">{t('form_title')}</div>
                    <Row>
                        {config.displayRegistrationOption && (
                            <>
                                <Col xs={12} sm={12} md={12} lg={5} xl={4}>
                                    <RegistrationInput setErrors={setErrors} />
                                </Col>
                                <Col xs={12} lg={1}>
                                    <div className="registration-or-make-logo">
                                        <span>{t('or')}</span>
                                    </div>
                                </Col>
                            </>
                        )}
                        <Col xs={12} sm={8} md={6} lg={5} xl={4}>
                            <MakeLogoInput setErrors={setErrors} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={8} lg={9}>
                            <Row>
                                <Col xs={12} sm={6} lg={4}>
                                    <ReferentialSelect
                                        label="other_brands"
                                        scope="make"
                                        setErrors={setErrors}
                                    />
                                </Col>

                                <Col xs={12} sm={6} lg={4}>
                                    <ReferentialSelect
                                        label="model"
                                        scope="model"
                                        error={errors.model}
                                        setErrors={setErrors}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6} lg={4}>
                                    <ReferentialSelect
                                        label="registration_date"
                                        scope="month"
                                        error={errors.month}
                                        option="registration_date_months"
                                        setErrors={setErrors}
                                    />
                                </Col>

                                <Col xs={12} sm={6} lg={4}>
                                    <ReferentialSelect
                                        label=""
                                        scope="year"
                                        error={errors.year}
                                        option="registration_date_years"
                                        setErrors={setErrors}
                                    />
                                </Col>
                            </Row>
                            <p className="form-help">{t('registration_date_help')}</p>
                            <Row>
                                <Col xs={12} sm={6} lg={4}>
                                    <ReferentialSelect
                                        label="fuel"
                                        scope="fuel"
                                        error={errors.fuel}
                                        setErrors={setErrors}
                                    />
                                </Col>

                                <Col xs={12} sm={6} lg={4}>
                                    <ReferentialSelect
                                        label="body"
                                        scope="body"
                                        error={errors.body}
                                        setErrors={setErrors}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                {displaySectionMoreDetails && (
                    <div className="form-section" id="more_details">
                        <div className="form-section-title">{t('form_bloc_title_2')}</div>
                        <Row>
                            <Col xs={12} sm={8} lg={9}>
                                <Row>
                                    <Col xs={12} sm={6} lg={4}>
                                        <ReferentialSelect
                                            label="doors"
                                            scope="door"
                                            error={errors.door}
                                            setErrors={setErrors}
                                        />
                                    </Col>

                                    <Col xs={12} sm={6} lg={4}>
                                        <ReferentialSelect
                                            label="gears"
                                            scope="gear"
                                            error={errors.gear}
                                            setErrors={setErrors}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} sm={6} lg={4}>
                                        <ReferentialSelect
                                            label="power"
                                            scope="engine"
                                            error={errors.engine}
                                            setErrors={setErrors}
                                        />
                                        <p className="form-help p-2">{t('engine_power_help')}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={8} lg={6}>
                                <ReferentialSelect
                                    label="version"
                                    scope="version"
                                    error={errors.version}
                                    setErrors={setErrors}
                                />
                                <p className="form-help">{t('version_help')}</p>
                            </Col>
                            <Col xs={12} sm={4} lg={{ size: 3, offset: 3 }}>
                                <Message
                                    className="info sm-absolute"
                                    display={displayEncouragementVersion}
                                    title={t('marketing_message_1_title') || ''}
                                    body={t('marketing_message_1_text') || ''}
                                />
                            </Col>
                        </Row>

                        <MileageInput error={errors.mileage} setErrors={setErrors} />

                        <Row>
                            <Col xs={12} sm={8} lg={9}>
                                <Row>
                                    <Col xs={12} md={12} lg={12} xl={8}>
                                        <ButtonRadioInput
                                            label="rolling_car"
                                            id="running"
                                            data={[
                                                { name: 'yes', value: 'yes' },
                                                { name: 'no', value: 'no' },
                                            ]}
                                            error={errors.running}
                                            setErrors={setErrors}
                                        />
                                    </Col>
                                    {vehicleState.running === 'no' && (
                                        <Col xs={12} md={12} lg={12} xl={8}>
                                            <ButtonRadioInput
                                                label="non_rolling_car_reason"
                                                id="notRollingReason"
                                                data={[
                                                    {
                                                        name: 'breakdown',
                                                        value: '1',
                                                    },
                                                    {
                                                        name: 'accident',
                                                        value: '2',
                                                    },
                                                    { name: 'other_reason', value: '3' },
                                                ]}
                                                error={errors.notRollingReason}
                                                setErrors={setErrors}
                                            />
                                            <SimpleInput
                                                label="non_rolling_car_detail"
                                                id="notRollingDescription"
                                                inputType="textarea"
                                                placeholder="non_rolling_car_detail_explanation"
                                                error={errors.notRollingDescription}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                )}

                {displaySectionAdditionalInformation && (
                    <div className="form-section" id="additional_information">
                        <div className="form-section-title">{t('form_bloc_title_3')}</div>
                        <Row>
                            <Col xs={12} sm={8} lg={9}>
                                <Row>
                                    <Col xs={12} md={12} lg={12} xl={8}>
                                        <ButtonRadioInput
                                            label="imported"
                                            id="imported"
                                            data={[
                                                { name: 'yes', value: 'N' },
                                                { name: 'no', value: 'Y' },
                                                { name: 'i_dont_know', value: 'W' },
                                            ]}
                                            error={errors.import}
                                            setErrors={setErrors}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <p className="form-help">{t('imported_help')}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={8} lg={9}>
                                <Row>
                                    {/* xs={12} md={8} lg={6} xl={4} */}
                                    <Col xs={12} md={12} lg={12} xl={8}>
                                        <ButtonRadioInput
                                            label="first_hand_car"
                                            id="firstHand"
                                            data={[
                                                { name: 'yes', value: 'Y' },
                                                { name: 'no', value: 'N' },
                                                { name: 'i_dont_know', value: 'W' },
                                            ]}
                                            error={errors.firstHand}
                                            setErrors={setErrors}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <p className="form-help">{t('first_hand_car_help')}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={8} lg={9}>
                                <Row>
                                    <Col xs={12} md={12} lg={12} xl={8}>
                                        <ButtonRadioInput
                                            label="service_book"
                                            id="history"
                                            data={[
                                                { name: 'yes', value: 'Y' },
                                                { name: 'no', value: 'N' },
                                                { name: 'i_dont_know', value: 'W' },
                                            ]}
                                            error={errors.history}
                                            setErrors={setErrors}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={8} lg={9}>
                                <Row>
                                    <Col xs={12} md={12} lg={12} xl={8}>
                                        <ButtonRadioInput
                                            label="invoices"
                                            id="serviceInvoice"
                                            data={[
                                                { name: 'yes', value: 'Y' },
                                                { name: 'no', value: 'N' },
                                                { name: 'i_dont_know', value: 'W' },
                                            ]}
                                            error={errors.serviceInvoice}
                                            setErrors={setErrors}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={8} lg={9}>
                                <Row>
                                    <Col xs={12} md={12} lg={12} xl={8}>
                                        <ButtonRadioInput
                                            label="buying_project"
                                            id="purchaseProject"
                                            data={buyingProject}
                                            error={errors.purchaseProject}
                                            setErrors={setErrors}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {Number(vehicleState.purchaseProject) === 6 && (
                            <Row>
                                <Col xs={12} sm={8} lg={9}>
                                    <Row>
                                        <Col xs={12} md={12} lg={12} xl={8}>
                                            <MakeSelectInput
                                                label="buying_project_brand"
                                                id="buyingProjectMake"
                                                error={errors.buyingProjectMake}
                                                setErrors={setErrors}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </div>
                )}

                {displaySectionPersonalDetails && (
                    <div className="form-section" id="personal_information">
                        <div className="form-section-title">{t('form_bloc_title_4')}</div>
                        <Row>
                            <Col xs={12} sm={8} lg={9} xl={6}>
                                <Row>
                                    <Col xs={12}>
                                        <EmailInput error={errors.email} setErrors={setErrors} />
                                    </Col>

                                    <Col xs={12} md={6}>
                                        <ZipCodeInput
                                            error={errors.zipCode}
                                            setErrors={setErrors}
                                        />
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <PhoneInput scope="phone" required={false} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={4} lg={3} xl={{ size: 3, offset: 3 }}>
                                <Message
                                    className="info sm-absolute"
                                    display
                                    title={t('marketing_message_2_title') || ''}
                                    body={t('marketing_message_2_text') || ''}
                                />
                            </Col>
                        </Row>

                        <p
                            className="form-cgu"
                            dangerouslySetInnerHTML={{
                                __html: t('form_cgu') || '',
                            }}
                        />

                        {/* <FormGroup
                            check
                            className={`form-group-privacy ${
                                errors.privacy && !privacyChecked ? 'invalid' : ''
                            }`}
                        >
                            <Input
                                type="checkbox"
                                id="privacy"
                                checked={privacyChecked}
                                onClick={() => setPrivacyChecked(!privacyChecked)}
                            />
                            <Label
                                htmlFor="privacy"
                                check
                                dangerouslySetInnerHTML={{
                                    __html: t('policy_of_privacy_html') || '',
                                }}
                            />
                        </FormGroup> */}
                    </div>
                )}

                <CtaBlock>
                    <Button onClick={handleSubmitForm}>{t('form_cta')}</Button>
                </CtaBlock>
                <p
                    className="footnote pb-3"
                    dangerouslySetInnerHTML={{
                        __html: t('form_gdpr_text') || '',
                    }}
                />
            </Container>
        </div>
    );
};

import { ThunkResult } from '../../../redux/configureStore';
import { checkCoteCQUseCase } from '../checkCoteCQ/checkCoteCQ.useCase';
import { checkClosePOSUseCase } from '../checkClosePOS/checkClosePOS.useCase';
import * as actionCreators from './actionCreators';

export const updateDealerListUseCase = (): ThunkResult<void> => async (dispatch, getState) => {
    dispatch(actionCreators.Actions.UpdateDealerListPending());
    const {
        data: { timeDetention, isRad },
    } = getState().record;
    const {
        dealerList: { data },
    } = getState().dealer;
    const { origin } = getState().client;
    let dealers = data;

    // Filter dealers List by distance
    if (dealers.length > 0) {
        // GET DEALERS WITH DISTANCE BETWENN 0 - 39 KM
        const smallPdvPrimeter = dealers.filter((dealer) => dealer.distance < 40);

        // GET DEALERS WITH DISTANCE BETWENN 0 - 99 KM
        let largePdvPerimeter = dealers.filter((dealer) => dealer.distance < 100);
        if (origin === 'StellantisYou')
            // GET DEALERS WITH DISTANCE BETWENN 0 - 999 KM
            largePdvPerimeter = dealers.filter((dealer) => dealer.distance < 1000);

        if (smallPdvPrimeter.length === 0) dispatch(checkClosePOSUseCase(false));
        if (smallPdvPrimeter.length === 0 && !isRad) {
            if (largePdvPerimeter.length > 0) dispatch(checkClosePOSUseCase(true));

            dealers = largePdvPerimeter;
        }
        if (smallPdvPrimeter.length > 0) dealers = largePdvPerimeter;

        // Filter dealers List by time detention (immat case)
    } else if (timeDetention > 0) {
        dispatch(checkCoteCQUseCase(true));
    } else {
        dispatch(checkClosePOSUseCase(false));
    }

    return dispatch(actionCreators.Actions.UpdateDealerListSucceed(dealers));
};

import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import useTranslation from '../hooks/useTranslation';
import { Feature } from './Feature';
import { FeatureGroup } from './FeatureGroup';
import phone from '../Themes/spoticar/img/sms_link.png';
import useScroll from '../hooks/useScroll';
import useTracker from '../hooks/useTracker';

export const RadConfirmation: React.FC = () => {
    const { t } = useTranslation();
    const { scrollToElement } = useScroll();
    const { trackerPushEvent } = useTracker();

    useEffect(() => {
        trackerPushEvent('confirmation_page_prise_rad', 'form/confirmation_page_prise_rad');
    }, []);

    useEffect(() => {
        scrollToElement('top', 45);
    }, []);

    return (
        <div className="page page-confirmation-hbs" id="top">
            <Container fluid className="p-0">
                <div className="mx-4">
                    <h1 className="hbs-confirmation-title">{t('hbs_confirmation_title')}</h1>
                    <Row className="hbs-confirmation-body">
                        <Col xs={12} sm={4} lg={2}>
                            <img src={phone} alt="rachat à domicile Spoticar" />
                        </Col>
                        <Col xs={12} sm={8} lg={10} className="hbs-confirmation-description">
                            <Row className="pb-3">
                                <Col xs={1} className="p-0">
                                    <div className="hbs-confirmation-number">1</div>
                                </Col>
                                <Col xs={11}>
                                    <p>{t('hbs_confirmation_step_1')}</p>
                                    <span className="hbs-confirmation-detail">
                                        {t('hbs_confirmation_step_1_detail')}
                                    </span>
                                </Col>
                            </Row>

                            <Row className="pb-3">
                                <Col xs={1} className="p-0">
                                    <div className="hbs-confirmation-number">2</div>
                                </Col>
                                <Col xs={11}>
                                    <p>{t('hbs_confirmation_step_2')}</p>
                                </Col>
                            </Row>

                            <Row className="pb-3">
                                <Col xs={1} className="p-0">
                                    <div className="hbs-confirmation-number">3</div>
                                </Col>
                                <Col xs={11}>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: t('hbs_confirmation_step_3') || '',
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <FeatureGroup>
                    <Feature label="hbs_confirmation_argument_1" icon="user" />
                    <Feature label="hbs_confirmation_argument_2" icon="smile" />
                    <Feature label="hbs_confirmation_argument_3" icon="sun" />
                </FeatureGroup>
            </Container>
        </div>
    );
};

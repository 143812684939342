import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { useSelector } from 'react-redux';
import useTranslation from '../hooks/useTranslation';
import { TRecord } from '../../../../hexagon/interfaces';
import { getClientSelector } from '../../view-models-generators/clientSelector';
import { FeatureGroup } from './FeatureGroup';
import { TextUtils } from '../../../../hexagon/shared/utils/TextUtils';
import { Feature } from './Feature';
import HowToWorkSection from './HowToWorkSection';
import { RestitutionPhoneInput } from './RestitutionPhoneInput';
import { getRecordSelector } from '../../view-models-generators/recordSelectors';
import useTracker from '../hooks/useTracker';

const RadRestitution: React.FC<TRecord> = (props) => {
    const { t } = useTranslation();

    const { vehicle, valuation, offerNumber } = props;
    const { client } = useSelector(getClientSelector);
    const { locale, currency, lang } = client.config;
    const { trackerPushEvent } = useTracker();
    const {
        data: { customer },
    } = useSelector(getRecordSelector);

    useEffect(() => {
        trackerPushEvent(
            'confirmation_page_rachat_domicile',
            'form/confirmation_page_rachat_domicile',
        );
    }, []);
    return (
        <div className="page page-restitution">
            <Container fluid className="px-0 mt-4">
                <div className="text-center">
                    <h1 className="valuation-title">{t('hbs_page_title')}</h1>

                    <p>
                        {(t('hbs_page_valuation_date') || '')
                            .replace('[VO_marque]', vehicle.makeName)
                            .replace('[VO_modele]', vehicle.modelName)
                            .replace(
                                '[DATE au format JJ.MM.ANNNE]',
                                valuation?.date.toLocaleDateString(lang),
                            )}
                    </p>
                    {valuation && (
                        <div className="valuation-value">
                            {TextUtils.formatPrice(locale, currency, valuation.value)}
                        </div>
                    )}

                    <p className="valuation-description">{t('hbs_page_valuation_detail')}</p>

                    <p className="valuation-reference">
                        {t('reference_id')} <strong>{offerNumber}</strong>
                    </p>
                </div>

                <div className="service-section">
                    <p className="service-section-explanation">
                        {t('hbs_page_service_explanation')}
                    </p>
                    <div className="h1 service-section-title"> {t('hbs_page_request_title')} </div>

                    <RestitutionPhoneInput customerPhone={customer.phone} />
                </div>

                <FeatureGroup>
                    <Feature
                        details="hbs_page_argument_1"
                        icon="clock"
                        style={{ minHeight: '30px' }}
                    />
                    <Feature
                        details="hbs_page_argument_2"
                        icon="lock"
                        style={{ minHeight: '30px' }}
                    />
                    <Feature
                        details="hbs_page_argument_3"
                        icon="check"
                        style={{ minHeight: '30px' }}
                    />
                </FeatureGroup>
            </Container>

            <HowToWorkSection />
        </div>
    );
};

export default RadRestitution;

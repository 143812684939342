import {
    TClientConfig,
    TClient,
    TJourney,
    TPurchaseDelay,
    TPurchaseType,
} from '../hexagon/interfaces';

import spoticar from './spoticar';

export const getClientConfig = (client: TClient): TClientConfig => {
    if (client === 'spoticar') return spoticar;

    return spoticar;
};

export const clients: TClient[] = ['spoticar'];

export const journeys: TJourney[] = ['sale'];

export const sellDelay: TPurchaseDelay[] = [
    { name: '1-3_weeks', value: '1' },
    { name: '1_month', value: '2' },
    { name: '3_months', value: '3' },
];
export const buyingProject: TPurchaseType[] = [
    { name: 'buying_project_new_car', value: '6' },
    { name: 'buying_project_used_car', value: '8' },
    { name: 'buying_project_no_project', value: '9' },
];

export const translationLinks = {
    spoticar: {
        dev: 'bqjxh242z8',
        prod: 'am8gxleuff',
    },
};

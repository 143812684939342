import React, { useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from './Map';
import { FeatureGroup } from './FeatureGroup';
import { Feature } from './Feature';
import { AppointmentResume } from './AppointmentResume';
import { cancelAppointmentUseCase } from '../../../../hexagon/usecases/cancelAppointment/cancelAppointment.useCase';
import { Picture } from './Picture';
import { TRecord } from '../../../../hexagon/interfaces';
import useScroll from '../hooks/useScroll';
import useTranslation from '../hooks/useTranslation';
import { getCancellationReasonsUseCase } from '../../../../hexagon/usecases/getCancellationReasons/getCancellationReasons.useCase';
import { ReasonInput } from './ReasonInput';
import { getCancelAppointmentReasonSelector } from '../../view-models-generators/cancelAppointmentReasonSelector';
import { confirmationAppointmentUseCase } from '../../../../hexagon/usecases/confirmationAppointment/confirmApplication.useCase';
import expertPicture from '../Themes/spoticar/img/expert_img.png';
import { isPostponedUseCase } from '../../../../hexagon/usecases/setPostpone/setPostpone.useCase';
import useTracker from '../hooks/useTracker';

export const Confirmation: React.FC<TRecord> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { scrollToElement } = useScroll();

    const record = props;
    const { reason } = useSelector(getCancelAppointmentReasonSelector);
    const [errors, setErrors] = useState<any>(null);
    const { trackerPushEvent } = useTracker();

    // Cancel Modal
    const [modalCancel, setModalCancel] = useState(false);
    const [trySubmit, setTrySubmit] = useState(false);
    const toggleModalCancel = () => setModalCancel(!modalCancel);

    const handlePrint = () => {
        window.print();
    };

    // get the confirmation value from URL
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const confirmation = searchParams.get('confirmation');

    // Confirmation Moadal

    const [showModal, setShowModal] = useState<boolean>(
        confirmation?.toLowerCase() === 'true' && !record.appointment?.smsConfirmation,
    );

    const toggleModalConfirmation = () => setShowModal(!showModal);
    const handleSubmitConfirmation = () => {
        setShowModal(!showModal);
        dispatch(confirmationAppointmentUseCase());
    };

    const handleSubmitCancel = () => {
        setTrySubmit(true);
        if (_.isEmpty(reason))
            setErrors({
                requireReason: true,
            });
        // else if (reason.id === 9 && !reason.comment) {
        //     setErrors({
        //         requireReasonDescription: true,
        //     });
        // }
        else setErrors(null);
    };

    // const handleSubmitPostpone = () => {
    //     dispatch(cancelAppointmentUseCase(record.uid));
    // };

    useEffect(() => {
        trackerPushEvent(
            'confirmation_page_prise_rdv',
            'form/confirmation_page_prise_rdv',
            record.uid,
        );
    }, []);
    useEffect(() => {
        if (modalCancel) {
            scrollToElement('top', 45);
        }
    }, [dispatch, modalCancel]);

    useEffect(() => {
        dispatch(getCancellationReasonsUseCase());
    }, [dispatch]);

    useEffect(() => {
        if (trySubmit) {
            if (!errors) dispatch(cancelAppointmentUseCase(record.uid));
            setTrySubmit(false);
        }
    }, [dispatch, trySubmit]);

    const addDefaultSrc = (target: any) => {
        target.src = expertPicture;
    };

    return (
        <div className="page page-confirmation" id="top">
            <Container fluid className="px-0">
                <div className="mx-4 mt-4 mb-3">
                    <Row className="mb-3">
                        <Col xs={12} sm={10}>
                            <h1 className="mb-0">{t('appointment_confirmation_title')}</h1>
                        </Col>
                        <Col xs={12} sm={2}>
                            <div
                                className="text-right text-nowrap print-button"
                                role="button"
                                aria-hidden="true"
                                onClick={() => handlePrint()}
                            >
                                <span className="px-2 small">{t('print')}</span>
                                <Picture background="icon-print" />
                            </div>
                        </Col>
                    </Row>
                    <p className="page-confirmation-reference">
                        {t('reference_id')} <strong>{record.offerNumber}</strong>
                    </p>

                    {record.appointment && (
                        <>
                            <AppointmentResume
                                date={record.appointment.appointmentDate}
                                hour={record.appointment.startHour}
                                label={false}
                            />

                            <Row className="appointment-confirmation-pos">
                                <Col xs={12} sm={6} lg={5}>
                                    <div className="h1">{t('appointment_confirmation_pos')}</div>
                                    <div className="appointment-confirmation-dealer">
                                        <div className="appointment-confirmation-dealer-avatar">
                                            <img
                                                src={
                                                    record.appointment.expertPicture
                                                        ? record.appointment.expertPicture
                                                        : expertPicture
                                                }
                                                onError={(e) => addDefaultSrc(e.target)}
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <p className="mb-2">{record.appointment.expertName}</p>
                                            <p>{record.appointment.realDealerName}</p>
                                        </div>
                                    </div>
                                    <p>
                                        <strong>{record.appointment.realDealerName}</strong>
                                    </p>
                                    <p className="mb-2"> {record.appointment.address}</p>
                                    <p>
                                        {record.appointment.zipCode} {record.appointment.city}
                                    </p>
                                    <p>{record.appointment.addressPrecision}</p>
                                </Col>

                                <Col xs={12} sm={6} lg={7}>
                                    <Map
                                        coordinates={{
                                            lat: record.appointment.latitude,
                                            lng: record.appointment.longitude,
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Modal isOpen={modalCancel} toggle={toggleModalCancel}>
                                <ModalHeader className="pl-4" toggle={toggleModalCancel}>
                                    <h1>{t('pop_up_appointment_cancelled_title')}</h1>
                                </ModalHeader>
                                <ModalBody className="pl-4">
                                    <div className="appointment-confirmation-dealer">
                                        <div className="appointment-confirmation-dealer-avatar">
                                            <img
                                                src={
                                                    record.appointment.expertPicture
                                                        ? record.appointment.expertPicture
                                                        : expertPicture
                                                }
                                                onError={(e) => addDefaultSrc(e.target)}
                                                alt="the expert"
                                            />
                                        </div>
                                        <div>
                                            <h3 className="mb-2">
                                                {record.appointment.expertName}
                                            </h3>
                                            <p>{record.appointment.realDealerName}</p>
                                        </div>
                                    </div>

                                    <ReasonInput
                                        scope="cancellationReason"
                                        label="pop_up_appointment_cancelled_reason"
                                        error={errors?.requireReason}
                                    />
                                    {/* {reason.id === 9 && (
                                        <CancelAppointmentTextInput
                                            id="cancellationReasonDescription"
                                            inputType="textarea"
                                            placeholder="pop_up_appointment_cancelled_other_reason"
                                            error={errors?.requireReasonDescription}
                                        />
                                    )} */}
                                </ModalBody>
                                <ModalFooter className="justify-content-center pt-0">
                                    <Button block onClick={() => handleSubmitCancel()}>
                                        {t('pop_up_appointment_cancelled_cta')}
                                    </Button>
                                </ModalFooter>
                            </Modal>

                            <Modal
                                isOpen={showModal}
                                toggle={toggleModalConfirmation}
                                className="appointment-confirmation-popup"
                            >
                                <ModalHeader toggle={toggleModalConfirmation} className="pb-0">
                                    <h1 className="mb-3">
                                        {t('pop_up_appointment_confirmation_title')}
                                    </h1>
                                </ModalHeader>
                                <ModalBody className="pt-0">
                                    <p> {t('pop_up_appointment_confirmation_detail')} </p>
                                    {record.appointment && (
                                        <div>
                                            <h3>{record.appointment.expertName}</h3>
                                            <p>{record.appointment.realDealerName}</p>
                                        </div>
                                    )}
                                </ModalBody>
                                <ModalFooter className="justify-content-center pt-0">
                                    <Button block onClick={() => handleSubmitConfirmation()}>
                                        {t('pop_up_appointment_confirmation_cta')}
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </>
                    )}
                    <Row>
                        <Col sm={6} xl={5}>
                            <Button
                                block
                                onClick={() => dispatch(isPostponedUseCase(true))}
                                className="postpone-appointment"
                            >
                                {t('cta_postpone_appointment')}
                            </Button>
                        </Col>
                        <Col sm={6} xl={5}>
                            <Button
                                block
                                className="cancel-appointment"
                                onClick={() => toggleModalCancel()}
                            >
                                {t('cta_cancel_appointment')}
                            </Button>
                        </Col>
                    </Row>
                </div>

                <div className="appointment-confirmation-documents">
                    <h3>{t('appointment_confirmation_documents')}</h3>
                    <p
                        dangerouslySetInnerHTML={{
                            __html:
                                (t('appointment_confirmation_documents_list') || '')
                                    .replace(/\n/g, ' <p /> ')
                                    .replace(':', ':<p />') || '',
                        }}
                    />
                    <hr />
                    <h3>{t('appointment_confirmation_appointment_steps')}</h3>

                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                (t('appointment_confirmation_appointment_steps_details') || '')
                                    .replace(/\n/g, '<p />')
                                    .replace('1.', '<p />1.') || '',
                        }}
                    />
                </div>

                <FeatureGroup>
                    <Feature label="appointment_confirmation_argument_1" icon="user" />
                    <Feature label="appointment_confirmation_argument_2" icon="smile" />
                    <Feature label="appointment_confirmation_argument_3" icon="sun" />
                </FeatureGroup>
            </Container>
        </div>
    );
};

import { TClientConfig } from '../hexagon/interfaces';

const config: TClientConfig = {
    lang: 'es',
    locale: 'es-ES',
    currency: 'EUR',
    identifier: 'spoticar',
    name: 'Spoticar',
    privateSellLink:
        'https://www.autocasion.com/vender-coche/coche-de-segunda-mano?make=[make]&year=[year]&month=[month]&utm_source=autobiz&utm_medium=iframe&utm_campaign=tasacion',
    noDealersAdsLinkValuation:
        'https://www.autocasion.com/vender-coche/coche-de-segunda-mano?utm_source=autobiz&utm_medium=appointment-page&utm_campaign=tasacion',
    noDealersAdsLinkSale:
        'https://www.autocasion.com/vender-coche/coche-de-segunda-mano?utm_source=autobiz&utm_medium=appointment-page&utm_campaign=venta-directa',
    displayRegistrationOption: false,

    emailConfirmation: true,
    nameRegex: "^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ ,.']*$",
    phoneRegex: '^(6|7)[0-9]{8}$',
    phoneRadRegex: '^(6|7)[0-9]{8}$',
    zipCodeRegex: '^[0-9]{5}$',
    registrationRegex: '^[0-9]{4}[a-zA-Z]{3}$|^[a-zA-Z]{1,2}[0-9]{4}-?[a-zA-Z]{2}$',
};
export default config;

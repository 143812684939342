import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { CtaBlock } from './CtaBlock';
import useTranslation from '../hooks/useTranslation';
import { TRecord } from '../../../../hexagon/interfaces';
import { VehicleInformations } from './VehicleInformations';
import { getNotRollingSelector } from '../../view-models-generators/notRollingSelectors';
import useScroll from '../hooks/useScroll';
import useVehicle from '../hooks/useVehicle';
import { ErrorPage } from './ErrorPage';

export const NotRolling: React.FC<TRecord> = ({ vehicle }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { scrollToElement } = useScroll();
    const { status } = useSelector(getNotRollingSelector);
    const history = useHistory();
    const { resetVehicle } = useVehicle();

    useEffect(() => {
        if (status === 'succeeded') scrollToElement('top', 45);
    }, [dispatch, status]);

    const handleSubmit = () => {
        resetVehicle('brandModel');
        history.push('/');
    };

    if (status === 'failed') {
        return <ErrorPage />;
    }

    return (
        <div className="page page-notrolling mt-4">
            <Container fluid>
                <div className="h1">{t('no_valuation_page_title')}</div>
                <p className="mb-4 text-justify">{t('no_valuation_page_description')}</p>
                <hr />
                <div className="h1">{t('no_valuation_page_your_car')}</div>

                <VehicleInformations vehicle={vehicle} />

                <CtaBlock>
                    <Button color="primary" onClick={handleSubmit} className="mb-5">
                        {t('no_valuation_page_cta')}
                    </Button>
                </CtaBlock>
            </Container>
        </div>
    );
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getRecordSelector } from '../../view-models-generators/recordSelectors';
import { getRecordInformationUseCase } from '../../../../hexagon/usecases/getRecordInformation/getRecordInformation.useCase';
import RecordProcess from './RecordProcess';
import { getisPostponedSelector } from '../../view-models-generators/isPostponedSelector';

export const RecordPage: React.FC = () => {
    const dispatch = useDispatch();
    const isPostponed = useSelector(getisPostponedSelector);

    const { recordUid } = useParams<{ recordUid: string }>();

    const {
        data: record,
        status,
        recordType: { data: recordType, status: recordTypeStatus },
    } = useSelector(getRecordSelector);

    useEffect(() => {
        dispatch(getRecordInformationUseCase(recordUid));
    }, [dispatch, recordUid, record.offerStatus, isPostponed]);

    return (
        <RecordProcess
            status={status}
            record={record}
            recordType={recordType}
            recordTypeStatus={recordTypeStatus}
        />
    );
};
